import React from 'react';
import { wizardFormContext } from 'components/WizardForm';
import { useFormikContext } from 'formik';
import { HealthDeclaration } from 'types';

import { useSaveHealthDeclaration } from './api';

export function useDiseaseSummaryEditHandler() {
  const { values, resetForm, setSubmitting } = useFormikContext<HealthDeclaration>();
  const { save, loading } = useSaveHealthDeclaration();
  const wizardFormGoBack = React.useContext(wizardFormContext);
  const addedDiseasesAmount = values.diseasesIds.length;

  const handleEdit = async () => {
    setSubmitting(true);
    const data = await save(values);
    resetForm();
    wizardFormGoBack(data ?? values);
  };

  return { handleEdit, loading, addedDiseasesAmount };
}
