import React from 'react';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import Paper from '@mui/material/Paper';
import { StepIconProps } from '@mui/material/StepIcon';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';

import { WizardFormStepperStep } from 'common/enums';
import AboutYouCompletedIcon from 'common/icons/AboutYouCompletedIcon';
import AboutYouIcon from 'common/icons/AboutYouIcon';
import DocumentsCompleteIcon from 'common/icons/DocumentsCompleteIcon';
import DocumentsIcon from 'common/icons/DocumentsIcon';
import YourHealthCompletedIcon from 'common/icons/YourHealthCompletedIcon';
import YourHealthIcon from 'common/icons/YourHealthIcon';

const stepIcons: {
  [key in WizardFormStepperStep]?: {
    default: OverridableComponent<SvgIconTypeMap>;
    completed: OverridableComponent<SvgIconTypeMap>;
  };
} = {
  [WizardFormStepperStep.AboutYou]: { default: AboutYouIcon, completed: AboutYouCompletedIcon },
  [WizardFormStepperStep.YourHealth]: {
    default: YourHealthIcon,
    completed: YourHealthCompletedIcon,
  },
  [WizardFormStepperStep.Documents]: { default: DocumentsIcon, completed: DocumentsCompleteIcon },
};

const StepIcon = ({
  active,
  completed,
  icon,
}: Omit<StepIconProps, 'icon'> & { icon: WizardFormStepperStep }) => {
  const isIconDisabled = icon === WizardFormStepperStep.Documents;
  const Icon = completed ? stepIcons[icon]!.completed : stepIcons[icon]!.default;

  return (
    <Paper
      data-testid="stepper-icon"
      sx={(theme) => ({
        padding: theme.spacing(2),
        backgroundColor: active ? theme.palette.secondary.main : undefined,
      })}
    >
      <Icon
        sx={(theme) => ({ color: isIconDisabled ? theme.palette.secondary.main : undefined })}
        fontSize="large"
        color="primary"
      />
    </Paper>
  );
};

export default StepIcon;
