import React, { useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import {
  AsyncStatus,
  HealthDeclaration,
  HealthDeclarationResponse,
  QuestionnaireStep,
  Signer,
  SignerStatus,
} from 'types';

import { useMountedRef } from 'common/hooks/util';
import { apiContext } from 'common/providers/ApiProvider';

const SHORT_POLLING_INTERVAL = 2000;
const LONG_POLLING_INTERVAL = 5000;
const MAX_POLLING_RETRIES = 15;

function useFetchEffect<T, K = T>(
  endpoint: () => Promise<AxiosResponse<T>>,
  data: K | undefined,
  setData: (data: T | undefined) => void
) {
  const isMountedRef = useMountedRef();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError>();

  React.useEffect(() => {
    endpoint()
      .then((response) => {
        if (isMountedRef.current) {
          setData(response.data);
          setLoading(false);
        }
      })
      .catch((error_: AxiosError) => {
        if (isMountedRef.current) {
          setError(error_);
          setLoading(false);
        }
      });
  }, [endpoint, setData, isMountedRef]);

  return { data, loading, error };
}

function createErrorHandler(
  setError: (error: AxiosError) => void,
  setLoading: (flag: boolean) => void,
  skip?: boolean
) {
  return function (error: AxiosError) {
    if (!skip) {
      setError(error);
      setLoading(false);
    }
  };
}

export function useFetchHealthDeclarationEffect() {
  const api = React.useContext(apiContext)!;
  const [data, setData] = useState<HealthDeclarationResponse>();

  return useFetchEffect<HealthDeclarationResponse>(api.fetchHealthDeclaration, data, setData);
}

export function useFetchQuestionnaire() {
  const api = React.useContext(apiContext)!;
  const [data, setData] = useState<QuestionnaireStep[]>();

  return useFetchEffect<QuestionnaireStep[]>(api.fetchQuestionnaire, data, setData);
}

export function useValidateQuestionnaire() {
  const api = React.useContext(apiContext)!;

  return React.useCallback(
    async (setValidating: (flag: boolean) => void) => {
      await api.validateQuestionnaire();
      setValidating(false);
    },
    [api]
  );
}

export function useSaveHealthDeclaration() {
  const api = React.useContext(apiContext)!;
  const isMountedRef = useMountedRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const history = useHistory();
  const save = React.useCallback(
    async (data: HealthDeclaration): Promise<HealthDeclaration | undefined> => {
      setLoading(true);
      try {
        const response = await api.saveHealthDeclaration({ healthDeclaration: data });
        if (isMountedRef.current) {
          setLoading(false);

          return response.data.healthDeclaration;
        }
      } catch (error_) {
        const axiosError = error_ as AxiosError;
        const status = axiosError.response?.status;
        if (status) {
          history.push(`${status}`);
        }
        const handler = createErrorHandler(setError, setLoading, !isMountedRef.current);
        handler(axiosError);
      }
    },
    [api, isMountedRef, history]
  );

  return { save, loading, error };
}

export function useSubmitHealthDeclaration(onSuccess: () => void, onFailure: () => void) {
  const api = React.useContext(apiContext)!;
  const isMountedRef = useMountedRef();
  const [status, setStatus] = React.useState(AsyncStatus.Initial);
  const submit = React.useCallback(() => {
    setStatus(AsyncStatus.InProgress);
    api
      .submitHealthDeclaration()
      .then(() => {
        if (isMountedRef.current) {
          onSuccess();
          setStatus(AsyncStatus.Success);
        }
      })
      .catch(() => {
        if (isMountedRef.current) {
          onFailure();
          setStatus(AsyncStatus.Failure);
        }
      });
  }, [api, isMountedRef, onSuccess, onFailure]);

  return { submit, status };
}

export function useFetchSignerEffect() {
  const api = React.useContext(apiContext)!;
  const { search } = useLocation();
  const insuredType = new URLSearchParams(search).get('ExternalReference');

  const isMountedRef = useMountedRef();
  const [data, setData] = useState<Signer>();
  const [loading, setLoading] = useState(true);
  const [polling, setPolling] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const [showMessage, setShowMessage] = useState(false);

  const timeOut = useRef<Nullable<NodeJS.Timeout>>(null);
  const retryCount = useRef<number>(0);

  const request = React.useCallback(() => {
    api
      .fetchSigner()
      .then((response) => {
        if (isMountedRef.current) {
          const [policyParty] = Object.keys(response.data.content);
          const signer = response.data.content[policyParty];
          const isPolicyPartySignerStatusNotSigned =
            insuredType === policyParty && signer.status === SignerStatus.NotSigned;
          const shouldStartPolling =
            isPolicyPartySignerStatusNotSigned ||
            signer.status === SignerStatus.Pending ||
            signer.status === SignerStatus.Waiting ||
            signer.hasPostponedDocuments;
          setData(signer);
          setLoading(false);
          if (shouldStartPolling && retryCount.current < MAX_POLLING_RETRIES) {
            setPolling(true);
            retryCount.current += 1;
            timeOut.current = setTimeout(
              () => {
                request();
              },
              retryCount.current > 5 ? LONG_POLLING_INTERVAL : SHORT_POLLING_INTERVAL
            );
            return;
          }
          retryCount.current = 0;
          setPolling(false);
          setShowMessage(isPolicyPartySignerStatusNotSigned);
        }
      })
      .catch(createErrorHandler(setError, setLoading, !isMountedRef.current));
  }, [api, isMountedRef, timeOut, retryCount, insuredType]);

  React.useEffect(() => {
    setLoading(true);
    request();
    return () => {
      if (timeOut.current) {
        clearTimeout(timeOut.current);
      }
    };
  }, [request]);

  return { data, loading, error, request, polling, showMessage };
}

export function useSigningUrl(retry: () => void = () => {}) {
  const api = React.useContext(apiContext)!;
  const isMountedRef = useMountedRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const history = useHistory();
  const requestSigningUrl = React.useCallback(() => {
    setLoading(true);
    api
      .fetchSigningUrl()
      .then((response) => {
        if (isMountedRef.current) {
          window.location.assign(response.data.signingUrl);
        }
      })
      .catch((error_: AxiosError) => {
        const status = error_.response?.status;
        if (status === 409 || status === 403) {
          retry();
        }
        if (status === 404) {
          history.push(`${status}`);
        }
        createErrorHandler(setError, setLoading, !isMountedRef.current)(error_);
      });
  }, [api, isMountedRef, retry, history]);

  return { requestSigningUrl, loading, error };
}

export function useSignedDocuments() {
  const api = React.useContext(apiContext)!;
  const isMountedRef = useMountedRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const download = React.useCallback(() => {
    setLoading(true);
    api
      .downloadSignedDocuments()
      .then(({ data, headers }) => {
        if (!isMountedRef.current) {
          return;
        }
        const zip = URL.createObjectURL(new Blob([data], { type: 'application/zip' }));
        const matches = /filename\*?=([^']*'')?([^;]*)/.exec(headers['content-disposition']);
        const fileName = matches != null && matches[2] ? decodeURIComponent(matches[2]) : '';

        const link = document.createElement('a');
        link.href = zip;
        link.download = fileName;
        link.click();
        link.remove();
        window.URL.revokeObjectURL(zip);
        setLoading(false);
      })
      .catch(createErrorHandler(setError, setLoading, !isMountedRef.current));
  }, [api, isMountedRef]);

  return { download, loading, error };
}
