import React from 'react';
import {AsyncStatus, BrowsingDisease, DiseasesCategory, SearchDisease} from 'types';

export const referencesContext = React.createContext<{
   browsingDiseases: BrowsingDisease[];
   searchDiseases: SearchDisease[];
   categories: DiseasesCategory[];
   status: AsyncStatus;
   error: any;
}>({
   browsingDiseases: [],
   searchDiseases: [],
   categories: [],
   status: AsyncStatus.Initial,
   error: null,
});
