import React from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { ReactComponent as GeneralErrorImage } from 'assets/images/general-error-image.svg';

const GeneralError = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '15%',
    }}
  >
    <GeneralErrorImage />
    <Typography variant="h1" sx={(theme) => ({ marginTop: theme.spacing(3) })}>
      <FormattedMessage id="generalError.label" />
    </Typography>
  </Box>
);

export default GeneralError;
