import React from 'react';
import {default as MuiDialog, DialogProps as MuiDialogProps} from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';

export type DialogProps = Omit<MuiDialogProps, 'title'> & {
   id: string;
   title: React.ReactNode;
   children: React.ReactNode;
   loading?: boolean;
   onClose: () => void;
};

const Dialog = ({id, title, children, onClose, loading, ...dialogProps}: DialogProps) => (
   <MuiDialog {...dialogProps} onClose={onClose} aria-labelledby={id}>
      <DialogTitle sx={{fontWeight: 600}} id={id}>
         {title}
      </DialogTitle>
      {children}
      {loading && <LinearProgress />}
   </MuiDialog>
);

export default Dialog;
