import React from 'react';
import { AxiosError } from 'axios';

import GeneralErrorPage from 'pages/GeneralErrorPage';
import SessionExpiredError from 'pages/SessionExpiredError';

type ErrorProps = {
  error: AxiosError;
};

const Error = ({ error }: ErrorProps) => {
  const isExpired = error.response?.status === 410;

  return isExpired ? <SessionExpiredError /> : <GeneralErrorPage />;
};

export default Error;
