import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import React from 'react';

export default function YourHealthIcon(props: SvgIconProps) {
   return (
      <SvgIcon {...props} viewBox="0 0 39 40">
         <g>
            <g>
               <path
                  d="M8.785 33.96c.03-1.41-1.112-2.55-2.591-2.588-1.553-.042-2.836 1.097-2.87 2.546-.033 1.438 1.176 2.664 2.652 2.69 1.559.029 2.777-1.12 2.809-2.649M29.5 20.22v4.918c0 2.023-.018 4.045.006 6.067.023 1.86-.756 3.291-2.19 4.364-1.816 1.36-3.89 1.72-6.081 1.475-1.564-.176-2.992-.731-4.19-1.801-1.181-1.055-1.806-2.364-1.797-4.001.023-4.243-.002-8.486.016-12.73.004-.779-.304-1.344-.885-1.79-1.583-1.215-4.402-1.227-6.002-.03-.612.457-.934 1.036-.928 1.849.025 3.093.014 6.186.002 9.279-.001.35.069.518.437.631 2.462.76 4.188 3.05 4.212 5.53.026 2.746-1.858 5.138-4.594 5.835-3.328.847-6.719-1.312-7.4-4.713-.522-2.602.932-5.333 3.466-6.41.477-.203.602-.438.597-.945-.026-3.093.013-6.186-.022-9.279-.021-1.928.782-3.381 2.269-4.483 2.771-2.054 7.241-1.98 9.941.173 1.33 1.06 2.051 2.436 2.045 4.2-.016 4.256.005 8.512-.014 12.768-.004.768.296 1.324.869 1.756 1.053.792 2.259 1.014 3.532.914.903-.071 1.75-.34 2.493-.895.598-.448.918-1.008.911-1.811-.026-3.49-.013-6.979-.005-10.468 0-.277-.069-.413-.345-.507-1.355-.461-2.364-1.356-3.118-2.595-1.796-2.95-3.621-5.881-5.43-8.823-.835-1.358-.97-2.825-.558-4.342.677-2.496 2.96-4.248 5.538-4.295.972-.017 1.744.713 1.764 1.67.02.928-.718 1.692-1.676 1.72-1.03.029-1.825.463-2.298 1.413-.381.763-.356 1.552.085 2.27 1.819 2.966 3.652 5.924 5.498 8.872.975 1.558 3.45 1.524 4.384-.035 1.735-2.894 3.446-5.804 5.22-8.673 1.259-2.035-.374-3.924-2.076-3.934-.87-.005-1.561-.627-1.662-1.426-.11-.86.358-1.686 1.15-1.868.428-.098.904-.083 1.343-.017 3.76.57 6.05 4.458 4.504 8.07-.103.238-.258.453-.392.677-1.732 2.897-3.466 5.793-5.196 8.69-.742 1.242-1.776 2.116-3.116 2.613l-.307.117"
                  transform="translate(-474 -72) translate(474 72)"
               />
            </g>
         </g>
      </SvgIcon>
   );
}
