export enum WizardFormStepperStep {
   Instructions,
   AboutYou,
   YourHealth,
   CompleteHealthDeclaration,
   Documents,
   CompleteDocuments,
}

export enum WizardFormStep {
   Instructions,
   GeneralInformation,
   Diseases,
   Questionnaire,
   Complete,
}
