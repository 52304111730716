import React from 'react';
import { SvgIcon } from '@mui/material';
import Grid from '@mui/material/Grid';
import { MaintenanceNotification } from 'components/MaintenanceNotification';

import { ReactComponent as Logo } from 'assets/images/logo.svg';

type HeaderProps = {
  children?: React.ReactNode;
};

const Header = ({ children }: HeaderProps) => (
  <>
    <Grid
      container
      wrap="nowrap"
      sx={(theme) => ({ paddingTop: theme.spacing(1) })}
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <SvgIcon sx={{ width: '65px', height: 'auto' }} component={Logo} inheritViewBox />
      </Grid>
      <Grid item sx={{ flex: '1 0', textAlign: 'center' }}>
        {children}
      </Grid>
    </Grid>
    {/* ENABLE THE MAINTENANCE NOTIFICATION WHEN THE MAINTENANCE DATES ARE CLEAR */}
     <MaintenanceNotification />
  </>
);

export default Header;
