import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Dialog, {DialogProps} from 'components/Dialog';
import { useFormikContext } from 'formik';
import {FormState, HealthDeclaration} from 'types';

type DiseasesConfirmationDialogProps = Omit<DialogProps, 'id' | 'children' | 'title'> & {
   onConfirm: (data: HealthDeclaration) => Promise<HealthDeclaration | undefined>;
};

const DiseasesConfirmationDialog = ({
   open,
   onClose,
   onConfirm,
   ...modalProps
}: DiseasesConfirmationDialogProps) => {
   const {values, setFieldValue, submitForm, setValues} = useFormikContext<HealthDeclaration>();
   const handleConfirm = async () => {
      const data = await onConfirm(values);
      if (data) {
         setValues(data);
      }
      setFieldValue('formState', FormState.Questionnaire, false);
      await submitForm();
      onClose();
   };

   return (
      <Dialog
         {...modalProps}
         id="diseases-confirmation-dialog"
         title={<FormattedMessage id="diseasesConfirmationDialog.title" />}
         open={open}
         onClose={onClose}
      >
         <DialogActions>
            <Button variant="outlined" color="primary" onClick={onClose}>
               <FormattedMessage id="diseasesConfirmationDialog.update" />
            </Button>
            <Button variant="contained" onClick={handleConfirm} color="primary" autoFocus>
               <FormattedMessage id="diseasesConfirmationDialog.complete" />
            </Button>
         </DialogActions>
      </Dialog>
   );
};

export default DiseasesConfirmationDialog;
