import { HealthDeclaration, SmokingAnswer } from 'types';

export const isBasicHealthDeclarationFilled = (formValues: HealthDeclaration): boolean => {
  const { height, weight, alcoholGlassPerDay, isSmoking, smokingUntilAge, hasMedicalIssues } =
    formValues;
  if (
    height === null ||
    weight === null ||
    alcoholGlassPerDay === null ||
    hasMedicalIssues === null
  ) {
    return false;
  }

  switch (isSmoking) {
    case SmokingAnswer.No:
    case SmokingAnswer.Yes:
      return true;
    case SmokingAnswer.Quit:
      return smokingUntilAge !== null;
    default:
      return false;
  }
};
