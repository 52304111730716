import React from 'react';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import AsyncButton from 'components/AsyncButton';
import Error from 'components/Error';
import Header from 'components/Header';

import { ReactComponent as DownloadImage } from 'assets/images/download-image.svg';
import { useSignedDocuments } from 'common/hooks';
import IntlProvider from 'common/providers/IntlProvider';

const DownloadSignedDocuments = () => {
  const { download, loading, error } = useSignedDocuments();

  return error ? (
    <Error error={error} />
  ) : (
    <IntlProvider>
      <Header />
      <Grid container spacing={2} sx={{ paddingTop: (theme) => theme.spacing(10) }}>
        <Grid item xs={12} md={8}>
          <Typography variant="h1" sx={{ paddingBottom: (theme) => theme.spacing(3) }}>
            <FormattedMessage id="downloadSignedDocuments.title" />
          </Typography>
          <Typography sx={{ paddingBottom: (theme) => theme.spacing(3) }}>
            <FormattedMessage id="downloadSignedDocuments.description" />
          </Typography>
          <AsyncButton variant="contained" color="primary" loading={loading} onClick={download}>
            <FormattedMessage id="downloadSignedDocuments.download" />
          </AsyncButton>
        </Grid>
        <Hidden mdDown>
          <Grid item md={4}>
            <DownloadImage />
          </Grid>
        </Hidden>
      </Grid>
    </IntlProvider>
  );
};

export default DownloadSignedDocuments;
