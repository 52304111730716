import React from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import Header from 'components/Header';
import { ContactLanguage } from 'types';

import { ReactComponent as SessionExpiredErrorImage } from 'assets/images/session-expired-error-image.svg';
import IntlProvider from 'common/providers/IntlProvider';

type SessionExpiredErrorProps = {
  contactLanguage?: ContactLanguage;
};

const SessionExpiredError = ({ contactLanguage }: SessionExpiredErrorProps) => (
  <IntlProvider contactLanguage={contactLanguage}>
    <Grid container wrap="nowrap" direction="column">
      <Header />
      <Grid item>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '15%',
            textAlign: 'center',
          }}
        >
          <SvgIcon
            sx={{ marginBottom: (theme) => theme.spacing(3), fontSize: '119px' }}
            component={SessionExpiredErrorImage}
            inheritViewBox
          />
          <Typography component="p" variant="h1">
            <FormattedMessage id="sessionExpiredError.label" />
          </Typography>
          <Typography component="p" variant="h1">
            <FormattedMessage id="sessionExpiredError.label1" />
          </Typography>
        </Box>
      </Grid>
    </Grid>
  </IntlProvider>
);

export default SessionExpiredError;
