export const required = (value: any) => {
  if (
    value === null ||
    value === undefined ||
    value === '' ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return ' ';
  }
};

export const subsectionRequired =
  (shouldValidate: boolean, subsectionAnswer: string | string[]) => () => {
    if (!shouldValidate) {
      return;
    }

    return required(subsectionAnswer);
  };

export const bloodPressureFormat = (value: string) =>
  !value || /^([1-9]|[1-9]\d)\/([1-9]|[1-9]\d)$/.test(value) ? undefined : ' ';

export const minLength = (min: number) => (value: string) =>
  !value || value.length >= min ? undefined : ' ';

export const transformValueFromStringToNumber = (stringValue: string) =>
  Number(String(stringValue).replace(',', '.'));

export const transformValueFromNumberToString = (numberValue: Nullable<number> | string) =>
  String(numberValue).replace('.', ',');

export const formatWeight = (weight: string) =>
  weight ? transformValueFromStringToNumber(weight) : null;
