import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import React from 'react';

export default function AboutYouCompletedIcon(props: SvgIconProps) {
   return (
      <SvgIcon {...props} viewBox="0 0 47 44">
         <g>
            <g>
               <path
                  d="M35.756 21.507c6.152 0 11.161 5.022 11.161 11.247S41.907 44 35.757 44c-6.152 0-11.161-5.021-11.161-11.246 0-6.225 5.01-11.247 11.16-11.247zm-25.37 1.65c2.296 2.552 5.614 4.156 9.304 4.156 1.11 0 2.184-.15 3.21-.422-.8 1.778-1.25 3.75-1.25 5.833 0 4.37 1.964 8.268 5.044 10.86H0v-.141c.019-1.7.437-18.064 9.88-20.178.163-.036.331-.072.505-.108zm25.37 1.317c-4.607 0-8.32 3.72-8.32 8.28 0 4.559 3.713 8.28 8.32 8.28 4.608 0 8.32-3.721 8.32-8.28 0-4.56-3.712-8.28-8.32-8.28zm1.582 4.443c.583-1.04 1.384-1.628 2.47-.972 1.112.671.945 1.702.36 2.715-1.156 2.002-2.285 4.02-3.465 6.006-.831 1.4-1.668 1.524-2.948.49-.679-.548-1.324-1.141-1.99-1.707-.85-.724-1.21-1.62-.502-2.59.785-1.073 1.726-.81 2.611-.044.219.189.444.369.827.687.928-1.61 1.798-3.088 2.637-4.585zM9.958 8.213c.716-4.94 5.217-8.471 10.167-8.198 4.933.272 8.93 4.305 9.207 9.315.046.84.006 1.685.006 2.483.247 2.995-.243 5.747-2.177 8.097-2.596 3.153-6.686 4.4-10.503 3.196-3.855-1.216-6.624-4.65-6.853-8.753-.114-2.04-.14-4.127.152-6.14z"
                  transform="translate(-394 -167) translate(394 167)"
               />
            </g>
         </g>
      </SvgIcon>
   );
}
