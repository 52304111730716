import axios, { AxiosResponse } from 'axios';
import {
  Diseases,
  DiseasesCategory,
  HealthDeclaration,
  HealthDeclarationResponse,
  QuestionnaireStep,
  SignerResponse,
} from 'types';

export const API_URL = process.env.REACT_APP_API_URL ?? '';
export const API_TOKEN_HEADER = 'X-SHARING-TOKEN';

export enum Endpoints {
  HealthDeclaration = '/health-declaration',
  VisitHealthDeclaration = '/health-declaration/visit',
  Diseases = '/health-declaration/diseases',
  DiseasesCategories = '/health-declaration/categories',
  Questionnaire = '/health-declaration/questionnaire',
  ValidateQuestionnaire = '/health-declaration/questionnaire/validation',
  Signer = '/sign/documents-signer',
  SigningUrl = '/sign/signingUrl',
  SignedDocuments = '/documents',
}

export const createApi = (token: string) => {
  const headers = { [API_TOKEN_HEADER]: token };
  const api = axios.create({
    baseURL: `${API_URL}/shared`,
    headers: { [API_TOKEN_HEADER]: token },
  });

  return {
    fetchHealthDeclaration: (): Promise<AxiosResponse<HealthDeclarationResponse>> =>
      api
        .put<void>(Endpoints.VisitHealthDeclaration, null, { headers })
        .then(() => api.get<HealthDeclarationResponse>(Endpoints.HealthDeclaration, { headers })),
    saveHealthDeclaration: (data: { healthDeclaration: HealthDeclaration }) =>
      api.patch(Endpoints.HealthDeclaration, data, { headers }),
    fetchDiseases: (): Promise<AxiosResponse<Diseases>> =>
      api.get<Diseases>(Endpoints.Diseases, { headers }),
    fetchDiseasesCategories: (): Promise<AxiosResponse<DiseasesCategory[]>> =>
      api.get<DiseasesCategory[]>(Endpoints.DiseasesCategories, {
        headers,
      }),
    submitHealthDeclaration: () => api.post<void>(Endpoints.HealthDeclaration, null, { headers }),
    fetchQuestionnaire: (): Promise<AxiosResponse<QuestionnaireStep[]>> =>
      api.get<QuestionnaireStep[]>(Endpoints.Questionnaire, { headers }),
    validateQuestionnaire: () => api.post<void>(Endpoints.ValidateQuestionnaire, null, { headers }),
    fetchSigner: () => api.get<SignerResponse>(Endpoints.Signer, { headers }),
    fetchSigningUrl: () =>
      api.post<{ signingUrl: string }>(Endpoints.SigningUrl, null, { headers }),
    downloadSignedDocuments: () =>
      api.get(`${Endpoints.SignedDocuments}/${token}`, { responseType: 'arraybuffer' }),
  };
};
