import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { AsyncStatus, BrowsingDisease, DiseasesCategory, SearchDisease } from 'types';

import {useMountedRef} from 'common/hooks';
import {apiContext} from 'common/providers/ApiProvider';
import { referencesContext } from 'common/providers/ReferencesProvider/context';

type ReferencesProviderProps = {
   shouldRequest: boolean;
   children: React.ReactNode;
};

const ReferencesProvider = ({shouldRequest, children}: ReferencesProviderProps) => {
   const intl = useIntl();
   const {Provider} = referencesContext;
   const isMountedRef = useMountedRef();
   const [browsingDiseases, setBrowsingDiseases] = React.useState<BrowsingDisease[]>([]);
   const [searchDiseases, setSearchDiseases] = React.useState<SearchDisease[]>([]);
   const [categories, setCategories] = React.useState<DiseasesCategory[]>([]);
   const [status, setStatus] = React.useState<AsyncStatus>(AsyncStatus.Initial);
   const [error, setError] = React.useState();
   const api = React.useContext(apiContext)!;
   const history = useHistory();

   React.useEffect(() => {
      if (!shouldRequest || (status !== AsyncStatus.Initial && status !== AsyncStatus.Failure)) {
         return;
      }
      setStatus(AsyncStatus.InProgress);
      Promise.all([api.fetchDiseases(), api.fetchDiseasesCategories()])
         .then((value) => {
            if (isMountedRef.current) {
               const [diseasesResponse, categoriesResponse] = value;
               setSearchDiseases(
                  diseasesResponse.data.search.filter((disease) => disease.locale === intl.locale)
               );
               setBrowsingDiseases(diseasesResponse.data.browsing);
               setCategories(categoriesResponse.data);
               setStatus(AsyncStatus.Success);
            }
         })
         .catch((reason) => {
            if (reason.response?.status) {
               history.push(`${status}`);
            }

            if (isMountedRef.current) {
               setError(reason);
               setStatus(AsyncStatus.Failure);
            }
         });
   }, [api, intl.locale, isMountedRef, shouldRequest, status, history]);

   return (
      <Provider value={{searchDiseases, browsingDiseases, categories, status, error}}>
         {children}
      </Provider>
   );
};

export default ReferencesProvider;
