import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Hidden } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import {stepperContext} from 'components/WizardFormStepper';
import StepIcon from 'components/WizardFormStepper/StepIcon';

import { WizardFormStepperStep } from 'common/enums';

export type WizardFormStepperProps = {
   initialStep?: number;
   children: React.ReactNode;
};

const steps: Array<Nullable<string>> = [
   null,
   'wizardFormStepper.aboutYou',
   'wizardFormStepper.yourHealth',
   null,
   'wizardFormStepper.documents',
];

export default function WizardFormStepper({
   initialStep = WizardFormStepperStep.Instructions,
   children,
}: WizardFormStepperProps) {
   const {Provider} = stepperContext;
   const [activeStep, setActiveStep] = React.useState<WizardFormStepperStep>(initialStep);

   return (
      <>
         <Stepper
            sx={(theme) => ({
               width: '400px',
               padding: theme.spacing(4, 0),
               [`& .${stepLabelClasses.label}.${stepLabelClasses.alternativeLabel}`]: {
                  color: theme.palette.text.primary,
                  marginTop: theme.spacing(1),
               },
               [theme.breakpoints.down('sm')]: {
                  width: 'auto',
               },
            })}
            activeStep={activeStep}
            alternativeLabel
            connector={<Fragment />}
         >
            {steps.map((labelId, index) => {
               // Renders Fragment for steps which has no Icon
               if (!labelId) {
                  return <Fragment key={index} />;
               }
               return (
                  <Step key={labelId} data-testid={labelId}>
                     <StepLabel icon={index} StepIconComponent={StepIcon}>
                        <Hidden smDown>
                           <FormattedMessage id={labelId} />
                        </Hidden>
                     </StepLabel>
                  </Step>
               );
            })}
         </Stepper>
         <Provider value={setActiveStep}>{children}</Provider>
      </>
   );
}
