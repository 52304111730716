import {
   ContactLanguage,
   FormState,
   Gender,
   Locale,
   QuestionnaireStepType,
   QuestionType,
   SignerStatus,
   SimpleAnswer,
   SmokingAnswer,
} from 'types/enums';

export interface HealthDeclarationResponse {
   language: ContactLanguage;
   healthDeclaration: HealthDeclaration;
   personalInformation: {gender: Nullable<Gender>};
   sharingDate: string;
   expirationDate: string;
   visitsLeft: number;
}

export interface HealthDeclaration {
  medicalOption: boolean;
  height: Nullable<number>;
  weight: Nullable<number> | string;
  isSmoking: Nullable<SmokingAnswer>;
  smokingUntilAge: Nullable<number>;
  alcoholGlassPerDay: Nullable<number>;
  hasMedicalIssues: Nullable<SimpleAnswer>;
  formState: Nullable<FormState>;
  diseasesIds: string[];
  questionnaire: QuestionnaireAnswers;
  isInstructionsRead: boolean;
}

export interface QuestionnaireAnswers {
   [key: string]: string | string[];
}

export interface SearchDisease {
   browsingId?: string;
   categoryId?: string;
   id: string;
   locale: Locale;
   name: string;
}

export interface BrowsingDisease {
   categoryId: string;
   mainCategoryId?: string;
   codeDutch: string;
   codeFrench: string;
   id: string;
   indexDiseases?: {[key in Locale]?: string[]};
   excludingDiseaseId?: string;
   isOther: boolean;
   isOtherOnly: boolean;
}

export const isSearchDisease = (
   disease: SearchDisease | BrowsingDisease
): disease is SearchDisease => (disease as SearchDisease).name !== undefined;

export const isBrowsingDisease = (
   disease: SearchDisease | BrowsingDisease
): disease is BrowsingDisease => (disease as BrowsingDisease).isOther !== undefined;

export interface Diseases {
   browsing: BrowsingDisease[];
   search: SearchDisease[];
}

export interface DiseasesCategory {
   id: string;
   code: string;
   categoryCode: string;
   parentId?: string;
}

export interface QuestionnaireStep {
   id: string;
   type: QuestionnaireStepType;
   categoryId: string;
   questions: Question[];
   diseaseIds?: string[];
}

export interface QuestionOptions {
   id: string;
   value?: string;
   questions: Question[];
   subsection: Question[];
   excludeOption?: string;
}

export interface Question {
   id: string;
   type: QuestionType;
   diseaseId: Nullable<string>;
   subcategoryId: Nullable<string>;
   optional?: boolean;
   options?: QuestionOptions[];
   singleOption?: boolean;
}

export interface SignerResponse {
   content: Record<string, Signer>;
}

export interface Signer {
   absentPolicyParty: boolean;
   hasUnsignedDocuments: boolean;
   documents: Document[];
   personalInfo: {firstName: string; surname: string; email: string};
   language: ContactLanguage;
   pendingSignedDocuments: boolean;
   canSignDocuments: boolean;
   hasPostponedDocuments: boolean;
   signingError: null;
   status: SignerStatus;
   sentToEmail: boolean;
   expirationDate: Nullable<string>;
   signingMode: string;
}

export interface Document {
   id: number;
   documentName: string;
   documentId: string;
   status: SignerStatus;
   proposalId: number;
   printable: boolean;
}

export const isQuestionnaireStep = (
   item: QuestionnaireStep | Question | QuestionOptions
): item is QuestionnaireStep =>
   (item as QuestionnaireStep).categoryId !== undefined ||
   (item as QuestionnaireStep).type === QuestionnaireStepType.Generic;

export const isQuestionOptions = (
   item: QuestionnaireStep | Question | QuestionOptions
): item is QuestionOptions => (item as QuestionOptions).subsection !== undefined;

export const isQuestion = (
   item: QuestionnaireStep | Question | QuestionOptions
): item is Question => (item as Question).diseaseId !== undefined;
