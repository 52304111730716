import React from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import diseasesFr from 'lang/diseases-fr-FR.json';
import diseasesNl from 'lang/diseases-nl-BE.json';
import fr from 'lang/fr-FR.json';
import nl from 'lang/nl-BE.json';
import questionnaireFr from 'lang/questionnaire-fr-FR.json';
import questionnaireNl from 'lang/questionnaire-nl-BE.json';
import { ContactLanguage, Locale, LocaleLabel } from 'types';

function flattenMessages(nestedMessages: NestedObject, prefix = '') {
   return Object.keys(nestedMessages).reduce((messages: Record<string, string>, key: string) => {
      const value = nestedMessages[key];
      const prefixedKey = prefix ? `${prefix}.${key}` : key;

      if (typeof value === 'string') {
         messages[prefixedKey] = value;
      } else {
         Object.assign(messages, flattenMessages(value, prefixedKey));
      }
      return messages;
   }, {});
}

const messagesMap = {
   [Locale.Nl]: {...nl, ...diseasesNl, ...questionnaireNl},
   [Locale.Fr]: {...fr, ...diseasesFr, ...questionnaireFr},
};

type IntlProviderProps = {
   contactLanguage?: ContactLanguage;
   children: React.ReactNode;
};

const IntlProvider = ({contactLanguage, children}: IntlProviderProps) => {
   let locale = Locale.Nl;

   if (contactLanguage) {
      locale = contactLanguage === ContactLanguage.French ? Locale.Fr : Locale.Nl;
   } else {
      const defaultLanguage = navigator.language.split(/[-_]/)[0];
      locale = defaultLanguage === LocaleLabel.Fr ? Locale.Fr : Locale.Nl;
   }

   const messages = React.useMemo(() => flattenMessages(messagesMap[locale]), [locale]);

   return (
      <ReactIntlProvider messages={messages} locale={locale}>
         {children}
      </ReactIntlProvider>
   );
};

export default IntlProvider;
