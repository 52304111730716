import React from 'react';
import GeneralError from 'components/GeneralError';
import Header from 'components/Header';
import { ContactLanguage } from 'types';

import IntlProvider from 'common/providers/IntlProvider';

type GeneralErrorProps = {
  contactLanguage?: ContactLanguage;
};

const GeneralErrorPage = ({ contactLanguage }: GeneralErrorProps) => (
  <IntlProvider contactLanguage={contactLanguage}>
    <Header />
    <GeneralError />
  </IntlProvider>
);

export default GeneralErrorPage;
