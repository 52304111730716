import { useEffect } from 'react';
import { scroller } from 'react-scroll';
import { useFormikContext } from 'formik';

const getFirstErrorKey = (object: NestedObject | string, keys: string[] = []): string => {
  if (object instanceof Object) {
    const firstKey = Object.keys(object)[0];
    const firstValue = Object.values(object)[0];
    if (firstKey !== 'id') {
      return getFirstErrorKey(firstValue, [...keys, firstKey]);
    }
  }
  return keys.join('.');
};

const ScrollToFirstError = () => {
  const { isValid, isSubmitting, errors } = useFormikContext();

  useEffect(() => {
    if (!isValid && isSubmitting) {
      const firstErrorKey = getFirstErrorKey(errors);
      scroller.scrollTo(firstErrorKey, {
        duration: 1000,
        smooth: true,
        offset: -100,
      });
      // timout to avoid ios overscroll issue, so that focus will happen after autoscroll
      setTimeout(() => {
        const inputField = global.window.document.getElementsByName(firstErrorKey);
        if (inputField.length > 0) {
          inputField[0].focus();
        }
      }, 1100);
    }
  }, [isSubmitting, isValid, errors]);

  return null;
};

export default ScrollToFirstError;
