import React from 'react';

import {apiContext} from 'common/providers/ApiProvider/context';
import {createApi} from 'common/services/api';

type ApiProviderProps = {
   token: string;
   children: React.ReactNode;
};

const ApiProvider = ({token, children}: ApiProviderProps) => {
   const {Provider} = apiContext;
   const api = React.useMemo(() => createApi(token), [token]);

   return <Provider value={api}>{children}</Provider>;
};

export default ApiProvider;
