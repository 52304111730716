import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

type AsyncButtonProps = ButtonProps & {
  loading: boolean;
};

const AsyncButton = ({ loading, children, ...restProps }: AsyncButtonProps) => (
  <Button
    {...restProps}
    disabled={restProps.disabled || loading}
    endIcon={loading ? <CircularProgress color="inherit" size={24} /> : undefined}
  >
    {children}
  </Button>
);

export default AsyncButton;
