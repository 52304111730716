import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import React from 'react';

export default function DocumentsCompleteIcon(props: SvgIconProps) {
   return (
      <SvgIcon {...props} viewBox="0 0 41 41">
         <g>
            <g>
               <path
                  d="M33.93 24.822c-1.075-.658-1.868-.067-2.444.976-.83 1.5-1.691 2.983-2.61 4.597-.379-.319-.602-.5-.818-.689-.876-.768-1.807-1.032-2.583.044-.701.973-.346 1.871.497 2.598.658.567 1.297 1.162 1.968 1.711 1.267 1.037 2.095.913 2.917-.49 1.168-1.992 2.285-4.017 3.429-6.024.58-1.016.745-2.049-.356-2.723m4.223 4.822c0-4.571-3.673-8.302-8.232-8.302-4.56 0-8.233 3.73-8.233 8.302 0 4.572 3.673 8.303 8.233 8.303 4.559 0 8.232-3.73 8.232-8.303m2.812 0c0 6.243-4.957 11.278-11.044 11.278-6.087 0-11.044-5.035-11.044-11.278 0-6.242 4.957-11.277 11.044-11.277 6.087 0 11.044 5.035 11.044 11.277m-27.468.489c-.076-.373-.739-.873-1.15-.887-2.413-.084-4.83-.039-7.245-.043-.702 0-1.303.291-1.153 1.023.077.374.724.845 1.152.888 1.195.12 2.41.042 3.619.042 1.207 0 2.415-.003 3.623.001.7.003 1.302-.295 1.154-1.024M3.915 12.552c-.032.944.693 1.034 1.439 1.032 3.064-.01 6.129-.004 9.194-.004 3.064 0 6.129-.006 9.193.005.698.002 1.391-.08 1.421-.932.033-.944-.695-1.03-1.44-1.03-6.13.007-12.258.007-18.387 0-.696 0-1.392.07-1.42.929m.04-5.764c.07.377.809.867 1.265.89 1.93.1 3.866.042 5.8.043 2.015 0 4.03-.008 6.045.003.73.004 1.422-.21 1.272-1.026-.07-.375-.817-.881-1.265-.889-3.948-.07-7.897-.036-11.846-.044-.728-.002-1.423.204-1.27 1.023m17.509 34.134H.988c-.545 0-.988-.444-.988-.992V.992C0 .444.443 0 .988 0h19.348c.545 0 .988.446.988.994l-.001 1.283v2.528c0 .418.001.836.003 1.253.004 1.49.178 1.659 1.698 1.662 1.689.004 3.377.002 5.066.001.546 0 .988.445.988.993v6.733c-2.344.133-4.536.86-6.431 2.036-2.702.006-5.403.003-8.104.003-3.065 0-6.13.006-9.194-.004-.746-.002-1.47.088-1.434 1.034.033.856.729.928 1.424.927 4.945-.006 9.89-.004 14.834 0-1.131 1.124-2.076 2.441-2.783 3.903h-2.857c-3.064 0-6.129.005-9.193-.005-.695-.003-1.392.066-1.425.924-.036.944.687 1.038 1.434 1.037 3.756-.004 7.512-.001 11.269 0-.424 1.36-.655 2.808-.655 4.313 0 4.623 2.162 8.718 5.502 11.307m6.153-35.243H23.45V1.47l4.168 4.209"
                  transform="translate(-555 -167) translate(555 167)"
               />
            </g>
         </g>
      </SvgIcon>
   );
}
