export enum ContactLanguage {
  Dutch = 'DUTCH',
  French = 'FRENCH',
}

export enum Locale {
  Nl = 'nl-BE',
  Fr = 'fr-FR',
}

export enum LocaleLabel {
  Nl = 'nl',
  Fr = 'fr',
}

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
}

export enum SimpleAnswer {
  Yes = 'YES',
  No = 'NO',
}

export enum SmokingAnswer {
  Yes = 'CURRENTLY_SMOKING',
  No = 'NEVER_SMOKED',
  Quit = 'USED_TO_SMOKE',
}

export enum AsyncStatus {
  Initial = 'initial',
  InProgress = 'inProgress',
  Success = 'success',
  Failure = 'failure',
}

export enum FormState {
  Basic = 'BASIC',
  Detailed = 'DETAILED',
  Questionnaire = 'QUESTIONNAIRE',
  Completed = 'COMPLETED',
  NotNeeded = 'NOT_NEEDED',
}

export enum QuestionnaireStepType {
  Generic = 'GENERIC',
  Specific = 'SPECIFIC',
}

export enum QuestionType {
  Text = 'TEXT',
  Date = 'DATE',
  CbGroup = 'CB_GROUP',
  CbGroupOther = 'CB_GROUP_OTHER',
  CbGroupRadio = 'CB_GROUP_RADIO',
  Radio = 'RADIO',
  TextArea = 'TEXTAREA',
  BloodPressure = 'BLOOD_PRESSURE',
  Percent = 'PERCENT',
}

export enum SignerStatus {
  Signed = 'SIGNED',
  NotSigned = 'NOT_SIGNED',
  Rejected = 'REJECTED',
  Pending = 'PENDING',
  Waiting = 'WAITING',
  Failed = 'FAILED',
  Unavailable = 'UNAVAILABLE',
}
