import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import React from 'react';

export default function AboutYouIcon(props: SvgIconProps) {
   return (
      <SvgIcon {...props} viewBox="0 0 40 43">
         <g>
            <g>
               <path
                  d="M29.524 22.595c.353.108.686.227.991.358C42.037 27.924 39.798 43 39.798 43H0v-.14c.019-1.677.442-17.821 9.986-19.907.164-.035.334-.071.51-.106 2.32 2.518 5.674 4.1 9.403 4.1 3.85 0 7.298-1.69 9.625-4.352zM10.064 8.103C10.787 3.23 15.335-.255 20.34.015c4.985.268 9.024 4.247 9.304 9.19.047.829.007 1.663.007 2.45.249 2.954-.246 5.67-2.201 7.987-2.624 3.112-6.757 4.342-10.615 3.154-3.896-1.2-6.694-4.587-6.926-8.636-.115-2.013-.141-4.072.154-6.057z"
                  transform="translate(-394 -69) translate(394 69)"
               />
            </g>
         </g>
      </SvgIcon>
   );
}
