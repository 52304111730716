import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import React from 'react';

export default function DocumentsIcon(props: SvgIconProps) {
   return (
      <SvgIcon {...props} viewBox="0 0 29 40">
         <g>
            <g>
               <g transform="translate(-555 -72) translate(555 72)">
                  <path d="M.983 0C.44 0 0 .434 0 .968v37.988c0 .535.44.968.983.968h26.944c.543 0 .983-.433.983-.968V8.502c0-.535-.44-.969-.983-.969-1.68 0-3.358.003-5.036-.001-1.512-.003-1.685-.169-1.69-1.621L21.2 4.688V2.222L21.2.97c0-.535-.44-.97-.983-.97H.983zm7.606 5.625c2.795-.003 5.59-.006 8.384.043.445.007 1.189.5 1.257.867.15.796-.54 1.005-1.264 1.001-2.003-.01-4.006-.004-6.01-.004-.517 0-1.034.004-1.55.009-1.407.01-2.814.021-4.216-.05-.454-.023-1.188-.5-1.258-.868-.151-.8.54-1 1.263-.999l1.52.002 1.874-.001zm-4.697 6.62c.028-.836.72-.906 1.412-.905 6.093.007 12.187.007 18.28 0 .741-.001 1.464.083 1.432 1.004-.03.832-.72.912-1.413.91-3.046-.011-6.093-.005-9.14-.005-3.047 0-6.094-.006-9.14.004h-.026c-.732 0-1.436-.097-1.405-1.007zm19.707 4.81c.691-.002 1.385.068 1.417.903.035.918-.684 1.012-1.427 1.01-6.094-.006-12.188-.006-18.281 0-.691.002-1.383-.068-1.416-.903-.036-.923.684-1.012 1.426-1.01 3.047.01 6.094.005 9.14.005l4.875.002c1.422 0 2.844-.002 4.266-.007zM3.892 23.673c.033-.836.726-.904 1.417-.901 3.046.01 6.093.004 9.14.004 3.047 0 6.094.006 9.14-.004.743-.003 1.464.089 1.427 1.01-.034.831-.726.904-1.418.903-6.093-.007-12.187-.007-18.28 0h-.009c-.74 0-1.453-.095-1.417-1.012zm3.155 4.814c1.743-.006 3.486-.013 5.228.046.41.014 1.068.502 1.143.865.148.711-.45 1.002-1.147 1-1.2-.005-2.401-.002-3.602-.002-1.2 0-2.41.077-3.598-.04-.425-.043-1.069-.503-1.145-.867-.15-.714.449-.999 1.146-.998h.323c.55 0 1.101-.001 1.652-.004zM23.314 5.54h4.144l-4.144-4.105V5.54z" />
               </g>
            </g>
         </g>
      </SvgIcon>
   );
}
