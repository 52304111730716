import React from 'react';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import CssBaseline from '@mui/material/CssBaseline';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';

type ThemeProviderProps = {
  children: NonNullable<React.ReactNode>;
};

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const theme = responsiveFontSizes(
    createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
      typography: {
        fontFamily: 'Open Sans',
        button: {
          textTransform: 'none',
        },
        h1: {
          fontSize: '1.875rem',
          fontWeight: 'normal',
        },
        body1: {
          '@media (max-width:599px)': {
            fontSize: '0.875rem',
          },
        },
        body2: {
          '@media (max-width:599px)': {
            fontSize: '0.75rem',
          },
        },
      },
      palette: {
        primary: { main: '#4d738a' },
        secondary: { main: '#e5f2f6' },
        text: { primary: '#022938' },
        error: { main: '#f44336' },
        background: { default: '#fff' },
        action: {
          disabled: 'rgba(255,255,255,1)',
          disabledBackground: 'rgba(0, 0, 0, 0.14)',
        },
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              fontWeight: 600,
              padding: '8px 30px',
            },
            outlined: {
              padding: '6px 30px',
              borderWidth: '2px',
              '&:hover': { borderWidth: '2px' },
              '&.Mui-disabled': {
                borderWidth: '2px',
                color: 'rgba(0, 0, 0, 0.14)',
              },
            },
            outlinedPrimary: {
              borderWidth: '2px',
              borderColor: '#4d738a',
              '&:hover': { borderWidth: '2px' },
              '&.Mui-disabled': { borderWidth: '2px' },
            },
            sizeSmall: {
              padding: '4px 30px',
            },
          },
          defaultProps: {
            disableElevation: true,
          },
        },
        MuiToggleButton: {
          styleOverrides: {
            root: {
              fontSize: '1rem',
              border: '2px solid #4d738a',
              padding: '6px 30px',
              fontWeight: 600,
              color: '#4d738a',
              '&.Mui-selected': {
                color: '#fff',
                backgroundColor: '#4d738a',
                '&:hover': {
                  backgroundColor: '#4d738a',
                },
              },
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            rounded: {
              borderRadius: '8px',
            },
          },
          defaultProps: {
            elevation: 0,
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            animated: {
              color: '#022938',
            },
            root: {
              display: 'flex',
              alignItems: 'center',
            },
          },
        },
        MuiInputAdornment: {
          styleOverrides: {
            root: {
              color: '#022938',
            },
          },
        },
        MuiFormHelperText: {
          styleOverrides: {
            root: {
              color: '#022938',
            },
          },
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              backgroundColor: '#4d738a',
              padding: '12px',
            },
          },
        },
        MuiTabs: {
          styleOverrides: {
            indicator: {
              height: '3px',
              backgroundColor: '#4d738a',
            },
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              textTransform: 'none',
              borderBottom: '3px solid #e6f2f5',
              '&.Mui-selected': {
                color: 'inherit',
              },
            },
            wrapped: {
              alignItems: 'flex-start',
            },
          },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              padding: '8px 24px 16px',
            },
          },
        },
        MuiCheckbox: {
          styleOverrides: {
            root: {
              opacity: '0.3',
              '&.Mui-checked': {
                opacity: '1',
              },
            },
          },
        },
        MuiRadio: {
          styleOverrides: {
            root: {
              opacity: '0.3',
              '&.Mui-checked': {
                opacity: '1',
              },
            },
          },
        },
        MuiSelect: {
          defaultProps: {
            IconComponent: ExpandMoreRoundedIcon,
          },
        },
        MuiContainer: {
          styleOverrides: {
            maxWidthLg: {
              '@media (min-width:1200px)': {
                maxWidth: '80rem',
              },
            },
          },
        },
      },
    })
  );

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
