import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import App from 'App';

if (process.env.NODE_ENV === 'development') {
   const {worker} = require('./mocks/browser');
   worker.start();
}

ReactDOM.render(
   <React.StrictMode>
      <Router>
         <Switch>
            <Route path="/:token">
               <App />
            </Route>
            <Route>Not found</Route>
         </Switch>
      </Router>
   </React.StrictMode>,
   document.getElementById('root')
);
