import React from 'react';
import { stepperContext } from 'components/WizardFormStepper';

import { WizardFormStepperStep } from 'common/enums';

export function useSetStepperStep(step: WizardFormStepperStep) {
  const setStepperStep = React.useContext(stepperContext)!;

  React.useEffect(() => {
    setStepperStep(step);
  }, [step, setStepperStep]);
}
